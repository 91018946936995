<template>
  <v-container fluid>
    <!-- <div class="d-flex justify-center mb-6">
      <img src="../assets/login.png" height="50" />
    </div> -->

    <v-row class="pa-md-4 mb-12" justify="center">
      <h1>Consulter et modifier les tables du groupe</h1>
    </v-row>

    <v-row class="py-md-4" justify="center">
      <v-card max-width="750" outlined>
        <v-list-item two-line>
          <v-list-item-content>
            <v-row class="pa-md-4 py-4" justify="center">
              <p>
                Nous avons besoin de vous authentifier pour vous donner l'accès
                à l'application.
              </p>
            </v-row>
            <v-row class="pa-md-4 pb-4" justify="center">
              <v-btn
                class="d-flex justify-center px-4 btn"
                outlined
                color="primary"
                @click="login"
                :disabled="!ready || loading"
                :loading="loading"
              >
                <div class="capitalize">Se connecter avec mon compte Avec</div>
              </v-btn>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-row>

    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
    >
      {{ snackbarMessage }}
    </v-snackbar>

    <v-dialog v-model="showDialogUnauthorized" width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Accès limité</span>
        </v-card-title>
        <v-card-text
          >Vous n'êtes pas autorisé à utiliser cette application pour
          l'instant.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showDialogUnauthorized = false">Quitter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as loginService from "@/service/login_service.js";
import * as loginStoreService from "@/service/login_store_service.js";
import * as roleService from "@/service/role_service.js";

import DgAuthService from "@/service/dgauth/dgauth_service.js";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

export default {
  name: "Login",
  components: {},
  mixins: [SnackBarMixin],

  data() {
    return {
      isSignIn: false,

      showDialogUnauthorized: false,

      ready: false,

      /**Le service de gestion des droits. */
      serviceDgAuth: null,

      /** Le provider pour la connection avec compte google */
      provider: null,

      loading: false,
    };
  },

  methods: {
    /** Demande d'authentification auprès de Google oauth puis auprès de DGauth pour les rôles */
    async login() {
      this.loading = true;

      // Récupération de l'interface d'authentification
      const auth = getAuth();

      // Lancement de l'interface de connexion via compte google
      signInWithPopup(auth, this.provider).then(async (result) => {
        // Récupération des datas de l'utilisateur.
        let user = result.user;

        // Envoie des infos utilisateur pour setter la connection
        await loginService.setConnection(user);

        // Demande d'obtention du token dgauth
        await loginService.connectDGauth(this.$api.getDgAuthApi());

        // Demande de génération des rôles
        roleService.setRole();

        // La connexion as pu se faire sans problème, on met à jour toutes les données de fin de connexion.
        loginService.endConnection();

        // Récupération de la route stockée avant demande login
        let route = loginStoreService.getExpectedRoute();
        if (route) {
          this.$router.replace(route);
        } else {
          this.$router.replace(route.home.path);
        }
      });

      this.loading = false;
    },
  },
  mounted() {
    // Instanciation du provider pour la connexion via google account
    this.provider = new GoogleAuthProvider();

    //le service de gestion de droits
    this.serviceDgAuth = new DgAuthService(this.$api.getDgAuthApi());

    // Fait patienter l'utilisateur une demi seconde pour que le package google s'initialise
    setTimeout(() => {
      this.ready = true;
    }, 1000);
  },
};
</script>
